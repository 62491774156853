
const utils = {
    somenteNumeros: (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault();
        } else {
            return true;
        }
    },
    formatarCPF: (valor) => {
        if (valor == undefined) {
            return valor
        }

        // Remove o que não for números
        valor = valor.replace(/\D/g, "")

        // verifica se tem 11 dígitos
        if (valor.length != 11) {
            return valor
        }

        valor = valor.replace(/(\d{3})(\d)/, "$1.$2")
        valor = valor.replace(/(\d{3})(\d)/, "$1.$2")
        valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return valor
    },
    scrollUp: () => {
        window.scrollTo(0, 0);
    }
}

const tempo = {
    // Espera receber uma string 'yyyy-mm-dd'
    formatarStringData: (data) => {
        const parts = data.split('-');
        const mydate = new Date(parts[0], parts[1] - 1, parts[2]);
        return mydate.toLocaleDateString('pt-BR');
    },
    // Espera receber uma string 'yyyy-mm-dd'
    formatarStringDataAdicionandoDias: (data, adicionarDias) => {
        const parts = data.split('-');
        let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
        mydate.setDate(mydate.getDate() + adicionarDias);
        return mydate.toLocaleDateString('pt-BR');
    },
    formatarData: (data) => {
        return data.toLocaleDateString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    formatarPeriodo: (periodoString) => {
        let partes = periodoString.split('/')
        let mydate = new Date(partes[1], partes[0] - 1, 1);
        return mydate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'numeric' })
    }
}
export default { tempo, utils };