<template>
  <div class="container-lg text-center mb-1 pb-1">
    <div class="row text-center justify-content-center m-3">
      <div class="col-md-6">
        <button
          type="button"
          class="btn btn-outline-secondary mt-3 noPrint"
          @click.prevent="this.$router.go(-1)"
        >
          Voltar
        </button>
        <span class="px-5"></span>
        <button
          id="Imprimir"
          type="button"
          class="btn btn-secondary mt-3 noPrint"
          @click.prevent="imprimir"
        >
          Imprimir
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenerateReport",
  methods: {
    imprimir() {
      window.print();
    },
  },
};
</script>

<style>
@media print {
  .noPrint {
    display: none;
  }
}

#relatorio {
  padding: 10px 30px;
}

.container-report {
  max-width: 900px !important;
  font-size: medium;
}
</style>
