import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/components/home/Home'
import Aluno from '@/components/aluno/Aluno'
import Matricula from '@/components/aluno/Matricula'
import Pagamento from '@/components/aluno/Pagamento'
import Dar from '@/components/aluno/Dar'
import Endereco from '@/components/aluno/Endereco'
import DarReport from '@/components/utils/report/DarReport'

const routes = [{
    path: '/',
    component: Home,
    meta: { isAuthenticated: false }

}, {
    path: '/alunos',
    component: Aluno,
    meta: { isAuthenticated: true }

}, {
    path: '/matriculas',
    component: Matricula,
    meta: { isAuthenticated: true }

}, {
    path: '/pagamentos',
    component: Pagamento,
    meta: { isAuthenticated: true }
}, {
    path: '/dar',
    component: Dar,
    meta: { isAuthenticated: true }
}, {
    path: '/endereco',
    component: Endereco,
    meta: { isAuthenticated: true }
}, {
    path: '/comprovante',
    component: DarReport,
    meta: { isAuthenticated: true, template: 'no-template' }
},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

import { updateToken } from "@/middlewares/updateToken";

router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
        updateToken(window.location.origin + to.path, function () {
            next()
        });
    } else {
        // This page did not require authentication
        next()
    }
})

export default router
