<template>
  <div>

    <Toast v-if="toast.exibir"
           :message="toast.message"
           :success="toast.success" />

    <Cards :nivel="1"></Cards>

    <p class="mt-3 mb-4"
       style="color: grey; font-size: 1.25rem">
      Para gerar o Documento de Arrecadação de Receitas (DAR), é necessário
      cadastrar-se na Secretaria de Estado da Fazenda (SEFAZ-PB). Preencha seu
      endereço e clique em 'Cadastrar'.
    </p>

    <div class="card">
      <h5 class="card-header">Endereço</h5>
      <div class="card-body">
        <Overlay :exibir="showOverlay">
          <form @submit.prevent="cadastrarContribuinte()"
                id="form">
            <div class="mb-3">
              <label for="cep"> CEP: </label>
              <input id="cep"
                     type="text"
                     class="form-control"
                     v-model="endereco.cep"
                     v-maska
                     data-maska="#####-###"
                     required />
            </div>

            <div class="mb-3">
              <label for="logradouro"> Logradouro: </label>
              <input id="logradouro"
                     type="text"
                     class="form-control"
                     v-model="endereco.logradouro"
                     maxlength="50"
                     required />
            </div>

            <div class="mb-3">
              <label for="numero"> Número: </label>
              <input id="numero"
                     type="text"
                     class="form-control"
                     v-model="endereco.numero"
                     maxlength="5"
                     required />
            </div>

            <div class="mb-3">
              <label for="complemento"> Complemento: </label>
              <input id="complemento"
                     type="text"
                     class="form-control"
                     v-model="endereco.complemento"
                     maxlength="40" />
            </div>

            <button type="submit"
                    class="btn btn-secondary">Cadastrar</button>
          </form>
        </Overlay>
      </div>
    </div>
    <button type="button"
            class="btn btn-outline-secondary mt-3"
            @click.prevent="this.$router.go(-1)">
      Voltar
    </button>
  </div>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import { vMaska } from "maska";
import Overlay from "../template/bootstrap/Overlay.vue";
import Toast from "../template/bootstrap/Toast.vue";
import Cards from "../template/Cards.vue";

export default {
  name: "EnderecoItem",
  components: { Overlay, Toast, Cards },
  directives: { maska: vMaska },
  data: function () {
    return {
      endereco: {},
      resposta: {},
      showOverlay: false,
      toast: { message: "", success: false, exibir: false },
    };
  },
  methods: {
    cadastrarContribuinte() {
      this.showOverlay = true;
      axios
        .post(`${environment.BASE_URL}/contribuinte`, this.endereco, {
          isAuthenticated: true,
        })
        .then((res) => {
          this.resposta = res.data.result;
          this.toast = { message: "Contribuinte cadastrado com sucesso na SEFAZ-PB", success: true, exibir: true };
          this.endereco = {};
          this.showOverlay = false;
        })
        .catch((error) => {
          this.toast = { message: error, success: false, exibir: true };
          this.showOverlay = false;
        });
    },
  },
  mounted() {
    this.$utils.scrollUp();
    if (Object.keys(this.$store.state.aluno).length === 0) {
      this.$router.push("/alunos");
    }
  },
};
</script>
