<template>
  <div class="container-lg containerFooter mb-5">
    <div class="row text-center justify-content-center">
      <div class="col-md-12">
        <img
          id="logoCodataFooter"
          :src="require(`@/assets/rodape_codata.png`)"
          alt="Logo da Codata"
          height="27"
        />
        <span class="px-3"></span>
        <img
          id="logoGovernoFooter"
          :src="require(`@/assets/rodape_gov.png`)"
          alt="Logo do Governo do Estado da Paraíba"
          height="47"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportFooter",
};
</script>

<style>
.containerFooter {
  margin-top: 60px;
}

#logoCodataFooter {
  margin: 15px 0;
}
</style>
