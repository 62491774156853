<template>
  <div>
    <Toast v-if="toast"
           :message="message" 
           @dismissed="resetToast" />
    <Cards :nivel="3"></Cards>
    <div class="info card card-body">
      <p><b>Atenção!</b></p>
      <p>A compensação bancária pode ocorrer em até 72 horas. Caso já tenha pago, aguarde até a atualização do
        status.
        <br>
        Se o Documento de Arrecadação de Receitas (DAR) já está vencido e não foi pago, é necessário
        gerar um novo DAR para o período desejado.
      </p>
    </div>
    <button type="button"
            class="btn btn-success mt-3 mb-3"
            @click.prevent="gerarDar()">
      Clique aqui para gerar o<br />Documento de Arrecadação de Receitas (DAR)
    </button>
    <div class="card">
      <h5 class="card-header">Documento de Arrecadação de Receitas (DAR)</h5>
      <div class="card-body">
        <Overlay :exibir="showOverlay">
          <div class="row">
            <div class="col-md-auto"><b>Período:</b></div>
            <div class="col-md-auto">{{ pagamento.periodo }}</div>
          </div>
          <div style="display: grid !important">
            <div class="table-responsive text-center mt-4">
              <table class="table"
                     ref="table">
                <thead>
                  <tr>
                    <th scope="col">Nosso número</th>
                    <th scope="col">Data de vencimento</th>
                    <th scope="col">Código de barras</th>
                    <th scope="col">Status</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody v-if="Object.keys(darEmAberto).length">
                  <tr>
                    <td>{{ darEmAberto.nossoNumero }}</td>
                    <td>
                      {{ $tempo.formatarStringData(darEmAberto.dataVencimento) }}
                    </td>
                    <td>
                      <a tabindex="0"
                         class="btn codBarras ml-1 btn-outline-dark btn-sm"
                         :data-bs-content="darEmAberto.codigoDeBarras"
                         data-bs-toggle="popover"
                         data-bs-trigger="manual"
                         sanitize="false"
                         @click.prevent="showPopover($event, darEmAberto.codigoDeBarras)">||||||||</a>
                    </td>
                    <td>
                      <span v-if="darEmAberto.status == null">
                        <a href="#"
                           @click.prevent="consultarPagamentoDAR(darEmAberto.nossoNumero)">verificar</a>
                      </span>
                      <span v-else>{{ darEmAberto.status }}</span>
                    </td>
                    <td>{{ $filters.currencyBRL(darEmAberto.valor) }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <td colspan="5">
                    Nenhum Documento de Arrecadação de Receitas (DAR) emitido
                    para esse período!
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </Overlay>
      </div>
    </div>

    <button type="button"
            class="btn btn-outline-secondary mt-3"
            @click.prevent="this.$router.go(-1)">
      Voltar
    </button>
  </div>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import Overlay from "../template/bootstrap/Overlay.vue";
import Toast from "../template/bootstrap/Toast.vue";
import Cards from "../template/Cards.vue";

import { Popover } from "bootstrap"
import { toClipboard } from '@soerenmartius/vue3-clipboard'

export default {
  name: "DarItem",
  components: { Overlay, Toast, Cards },
  data: function () {
    return {
      resposta: {},
      showOverlay: true,
      toast: false,
      message: "",
      dar: {},
      contribuinteCadastrado: false,
    };
  },
  methods: {
    gerarDar() {
      this.showOverlay = true;
      this.montarRequisicaoDar();

      // É necessário enviar o endereço na requisição
      if (this.usuario.endereco === undefined) {
        this.usuario.endereco = {};
      }

      axios
        .get(`${environment.BASE_URL}/contribuinte`, {
          isAuthenticated: true,
        })
        .then((res) => {
          this.contribuinteCadastrado = res.data.result;
          if (this.contribuinteCadastrado) {
            this.emitirDar();
          } else {
            this.$router.push("/endereco");
          }
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false;
        });
    },
    emitirDar() {
      axios
        .post(`${environment.BASE_URL}/dar/emitir`, this.requisicaoDar, {
          isAuthenticated: true,
        })
        .then((res) => {
          this.dar = res.data.result;
          this.showOverlay = false;
          this.$store.commit("setDadosDar", this.dar);
          this.$router.push("/comprovante");
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false
        });
    },
    montarRequisicaoDar() {
      let vencimento = new Date();
      vencimento.setDate(vencimento.getDate() + 2);

      this.requisicaoDar = {
        dataPagamento: this.$tempo.formatarData(vencimento),
        dataVencimentoOriginal: this.$tempo.formatarData(vencimento),
        informacoesComplementares: "",
        pagamentoBancoBrasil: true,
        periodoReferencia: this.$tempo.formatarPeriodo(this.pagamento.periodo),
        idPagamento: this.pagamento.id,
      };
    },
    consultarPagamentoDAR(nossoNumero) {
      this.showOverlay = true;
      axios
        .get(`${environment.BASE_URL}/dar/${nossoNumero.trim()}`, {
          isAuthenticated: true,
        })
        .then((res) => {
          if (res.data.status === "FAIL") {
            this.message = res.data.message;
            this.toast = true;
          } else {
            this.resposta.forEach((el) => {
              if (el["nossoNumero"] == nossoNumero) {
                el["status"] = res.data.result.stLancamento;
              }
            });
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false;
        });
    },
    showPopover(event, value) {
      event.target.title = "Código de barras copiado para a área de transferência!"
      toClipboard(value);
      let bsPopover = new Popover(event.target, { placement: 'top' })
      bsPopover.show()
      setTimeout(() => {
        if (bsPopover != null) {
          bsPopover.dispose()
          event.target.title = ""
        }
      }, 5000)
    },
    resetToast() {
      this.toast = false;
      this.message = '';
    },
  },
  computed: {
    usuario: {
      get() {
        return this.$store.state.usuario;
      },
      set(valor) {
        this.$store.commit("setUsuario", valor);
      },
    },
    aluno: {
      get() {
        return this.$store.state.aluno;
      },
      set(valor) {
        this.$store.commit("setAluno", valor);
      },
    },
    matricula: {
      get() {
        return this.$store.state.matricula;
      },
      set(valor) {
        this.$store.commit("setMatricula", valor);
      },
    },
    pagamento: {
      get() {
        return this.$store.state.pagamento;
      },
      set(valor) {
        this.$store.commit("setPagamento", valor);
      },
    },
    darEmAberto(){
      if(!this.resposta.length){
        return {}
      }
      const dar = this.resposta.find(
        (el) =>
          new Date(el["dataVencimento"] + 'T23:59:59') >= new Date(),
      )
      return dar != undefined ? dar : {}
    }
  },
  mounted() {
    this.$utils.scrollUp();
    if (Object.keys(this.aluno).length === 0) {
      this.$router.push("/alunos");
    } else {
      axios
        .get(`${environment.BASE_URL}/aluno/pagamento/${this.pagamento.id}`, {
          isAuthenticated: true,
        })
        .then((res) => {
          if (res.data.status === "FAIL") {
            this.message = res.data.message;
            this.toast = true;
          } else {
            this.resposta = res.data.result;
            this.resposta.forEach((el) => {
              if (el["status"] != "QUITADO") {
                el["status"] = null;
              }
            });
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false;
        });
    }
  },
};
</script>

<style>
.codBarras {
  border: none !important;
}
</style>