import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
    state() {
        return {
            usuario: {},
            aluno: {},
            matricula: {},
            pagamento: {},
            dadosDar: {},
        }
    },
    mutations: {
        setUsuario(state, payload) {
            state.usuario = payload
        },
        setAluno(state, payload) {
            state.aluno = payload
        },
        setMatricula(state, payload) {
            state.matricula = payload
        },
        setPagamento(state, payload) {
            state.pagamento = payload
        },
        setDadosDar(state, payload) {
            state.dadosDar = payload
        },
    }
})

export default store
