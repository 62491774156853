<template>
  <div>
    <Toast v-if="toast" :message="message" />
    <Cards :nivel="1"></Cards>
    <div class="card mb-5">
      <h5 class="card-header">Alunos</h5>
      <div class="card-body">
        <Overlay :exibir="showOverlay">
          <div style="display: grid !important">
            <div class="table-responsive text-center mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Bolsa</th>
                    <th scope="col">Matrículas</th>
                  </tr>
                </thead>
                <tbody v-if="Object.keys(resposta).length">
                  <tr v-for="item in resposta" :key="item.cpf">
                    <td>{{ item.nome }}</td>
                    <td>{{ $utils.formatarCPF(item.cpf) }}</td>
                    <td style="max-width: 200px;">{{ item.bolsa }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-link m-0 p-0"
                        @click.prevent="verMatriculas(item)"
                      >
                        <i
                          class="bi-pass"
                          style="font-size: 1.3rem; color: cornflowerblue"
                        ></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <td colspan="5">Nenhum registro encontrado!</td>
                </tbody>
              </table>
            </div>
          </div>
        </Overlay>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import environment from "@/environment";
import Overlay from "../template/bootstrap/Overlay.vue";
import Toast from "../template/bootstrap/Toast.vue";
import Cards from "../template/Cards.vue";
import { setUsuarioKeycloak } from "@/middlewares/updateToken";

export default {
  name: "MatriculaItem",
  components: { Overlay, Toast, Cards },
  data: function () {
    return {
      resposta: {},
      showOverlay: true,
      toast: false,
      message: "",
    };
  },
  methods: {
    verMatriculas(aluno) {
      this.$store.commit("setAluno", aluno);
      this.$router.push("/matriculas");
    },
  },
  mounted() {
    this.$utils.scrollUp();
    setUsuarioKeycloak();
    axios
      .get(`${environment.BASE_URL}/aluno/lista`, {
        isAuthenticated: true,
      })
      .then((res) => {
        if (res.data.status === "FAIL") {
          this.message = res.data.message;
          this.toast = true;
        } else {
          this.resposta = res.data.result;
        }
        this.showOverlay = false;
      })
      .catch((error) => {
        this.message = error;
        this.toast = true;
        this.showOverlay = false;
      });
  },
};
</script>
