<template>
  <div>
    <div id="relatorio">
      <ReportHeader />
      <div
        class="container-lg container-report"
        v-if="this.dadosDar.nossoNumero != undefined"
      >
        <div class="row text-center">
          <div class="col">
            <h4 class="font-weight-bold">
              Secretaria de Estado da Juventude, Esporte e Lazer
            </h4>
          </div>
        </div>
        <div class="row text-center mb-4">
          <div class="col">
            <h5>Documento de Arrecadação de Receitas (DAR)</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b>Instruções</b>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            Pagar nas agências do Banco do Brasil ou correspondentes bancários.
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-12">Não receber após vencimento.</div>
        </div>

        <div class="row mb-2" v-for="[chave, valor] in report" :key="chave">
          <div class="col-md-3">
            <b>{{ chave }}</b>
          </div>
          <div class="col-md-9">
            {{ valor }}
          </div>
        </div>
      </div>
      <ReportFooter />
    </div>
    <GenerateReport />
  </div>
</template>

<script>
import ReportHeader from "@/components/utils/report/template/Header.vue";
import GenerateReport from "@/components/utils/report/template/GenerateReport.vue";
import ReportFooter from "@/components/utils/report/template/Footer.vue";
export default {
  name: "DarReport",
  components: { ReportHeader, GenerateReport, ReportFooter },
  data: function () {
    return {
      dadosDar: {},
      report: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.dadosDar = this.$store.state.dadosDar;
    if (this.dadosDar.nossoNumero != undefined) {
      this.report = new Map()
        .set("Cedente: ", "Secretaria de Estado da Juventude, Esporte e Lazer")
        .set(
          "Data de emissão: ",
          this.$tempo.formatarStringData(this.dadosDar.dataEmissao)
        )
        .set(
          "Data de vencimento: ",
          this.$tempo.formatarStringData(this.dadosDar.dataVencimento)
        )
        .set("Nosso número: ", this.dadosDar.nossoNumero)
        .set("Código de barras: ", this.dadosDar.codigoDeBarras)
        .set("Valor: ", this.$filters.currencyBRL(this.dadosDar.valor));
    } else {
      this.$router.push("/alunos");
    }
  },
};
</script>
