import { app } from "@/main.js";

export function updateToken(redirectUri, callback) {
  if (!app.config.globalProperties.$keycloak.authenticated) {
    // The page is protected and the user is not authenticated. Force a login.
    app.config.globalProperties.$keycloak.login({ redirectUri });
  } else {
    try {
      app.config.globalProperties.$keycloak
        .updateToken(60)
        .then(function () {
          callback();
        })
        .catch(function () {
          app.config.globalProperties.$keycloak.login({ redirectUri });
        });
    } catch (err) {
      console.log("Failed to refresh token");
    }
  }
}

export function logout() {
  app.config.globalProperties.$keycloak.logout({
    redirectUri: window.location.origin,
  });
}

export function setUsuarioKeycloak() {
  if (
    (app.config.globalProperties.$keycloak.token &&
      app.config.globalProperties.$keycloak.idToken) != ""
  ) {
    app.config.globalProperties.$store.commit("setUsuario", {
      nome: app.config.globalProperties.$keycloak.tokenParsed.name,
      cpf: app.config.globalProperties.$keycloak.tokenParsed.preferred_username,
      telefone: app.config.globalProperties.$keycloak.tokenParsed.phone_number,
      email: app.config.globalProperties.$keycloak.tokenParsed.email,
      endereco: { cep: "" },
    });
  }
}
