<template>
  <header class="header">
    <div>
      <!-- <router-link to="/">
        <img :src="require(`@/assets/logo.png`)" alt="Logo" class="mr-4" />
      </router-link> -->
    </div>
    <div>
      <h1 class="title">
        <router-link to="/alunos">
          <span>Vila Olímpica</span>
        </router-link>
      </h1>
    </div>
  </header>
</template>

<script>
export default {
  name: "MyHeader",
};
</script>

<style>
.header h1 a {
  text-decoration: none;
  color: #212529;
}

.header {
  grid-area: header;
  background-image: url("@/assets/header.png");
  background-repeat: repeat-x;
  background-size: 100% 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: 100;
  flex-grow: 1;
  text-align: center;
}
</style>