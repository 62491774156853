<template>
  <div>
    <div class="position-relative">
      <div class="toast-container position-fixed top-0 start-50 translate-middle-x"
           style="margin-top: 150px">
        <div ref="el"
             class="toast"
             data-bs-autohide="false">
          <div class="toast-header"
               :class="success ? 'toast-header-success' : 'toast-header-error'">
            <strong class="me-auto">{{ success ? 'Operação realizada com sucesso!' : 'Erro' }}</strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    @click="dismiss"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body"
               :class="success ? 'toast-body-success' : 'toast-body-error'">
            {{ exibirMensagemErro(message) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
  name: "BootstrapToast",
  props: ["message", "success"],
  data() {
    return {
      toast: null,
    };
  },
  methods: {
    exibirMensagemErro(error) {
      if (error && error.response && error.response.data) {
        if (error.response.data.message) {
          return error.response.data.message;
        }
        return error.response.data;
      } else if (typeof error === "string") {
        return error;
      } else if (error && error.message) {
        return error.message;
      }
      return error;
    },
    dismiss() {
      this.$emit('dismissed');
    },
  },
  mounted() {
    var toast = new Toast(this.$refs.el);
    toast.show();
  },
};
</script>

<style>
.toast-header-error {
  color: #721c24;
  background-color: rgba(248, 215, 218, 0.85);
  border-bottom-color: rgba(245, 198, 203, 0.85);
}

.toast-body-error {
  background-color: rgba(252, 237, 238, 0.85);
  border-color: rgba(245, 198, 203, 0.85);
  color: #721c24;
}

.toast-header-success {
  color: #155724;
  background-color: rgba(212, 237, 218, 0.85);
  border-bottom-color: rgba(195, 230, 203, 0.85);
}

.toast-body-success {
  background-color: rgba(230, 245, 233, 0.85);
  border-color: rgba(195, 230, 203, 0.85);
  color: #155724;
}
</style>