<template>
  <header class="headerReport">
    <div>
      <h3 class="text-center mt-2 mb-4">Vila Olímpica</h3>
    </div>
  </header>
</template>

<script>
export default {
  name: "ReportHeader",
};
</script>

<style>
.headerReport {
  display: flex;
  justify-content: center;
}
</style>
