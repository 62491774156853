<template>
  <div>
    <div aria-busy="true" class="position-relative" v-if="exibir">
      <slot></slot>
      <div style="inset: 0px; z-index: 10">
        <div
          class="position-absolute bg-transparent rounded-sm"
          style="inset: 0px; opacity: 0.9; backdrop-filter: blur(2px)"
        ></div>
        <div
          class="position-absolute"
          style="
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          "
        >
          <span aria-hidden="true" class="spinner-border"><!----></span>
        </div>
      </div>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverlayItem",
  props: ["exibir"],
};
</script>