<template>
  <div>
    <Toast v-if="toast" :message="message" />
    <Cards :nivel="3"></Cards>
    <div class="card">
      <h5 class="card-header">Pagamentos</h5>
      <div class="card-body">
        {{ resposta.id }}
        <Overlay :exibir="showOverlay">
          <div style="display: grid !important">
            <div class="table-responsive text-center mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Período</th>
                    <th scope="col">Valor da mensalidade</th>
                    <th scope="col">Status</th>
                    <th scope="col">
                      Documento de Arrecadação<br />de Receitas (DAR)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resposta" :key="item.id">
                    <td>{{ item.periodo }}</td>
                    <td>{{ $filters.currencyBRL(item.valor) }}</td>
                    <td>
                      <span v-if="item.pago">QUITADO</span>
                      <span v-else-if="item.cancelado">CANCELADO</span>
                      <span v-else>
                        <a v-if="item.status == undefined"
                          href="#"
                          @click.prevent="atualizarStatus(item)"
                          >Verificar</a
                        >
                        <span v-else>{{item.status}}</span>
                      </span>
                    </td>
                    <td>
                      <a
                        v-if="
                          item.status != 'EM ABERTO' &&
                          !item.pago &&
                          !item.cancelado
                        "
                      >
                        Clique em "Verificar" para prosseguir.</a
                      >
                      <a
                        v-else-if="item.status == 'EM ABERTO'"
                        href="#"
                        @click.prevent="verDars(item)"
                        >Gerar/consultar</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Overlay>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-outline-secondary mt-3"
      @click.prevent="this.$router.go(-1)"
    >
      Voltar
    </button>
  </div>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import Overlay from "../template/bootstrap/Overlay.vue";
import Toast from "../template/bootstrap/Toast.vue";
import Cards from "../template/Cards.vue";

export default {
  name: "PagamentoItem",
  components: { Overlay, Toast, Cards },
  data: function () {
    return {
      resposta: "",
      showOverlay: true,
      toast: false,
    };
  },
  methods: {
    verDars(pagamento) {
      this.$store.commit("setPagamento", pagamento);
      this.$router.push("/dar");
    },
    atualizarStatus(pagamento) {
      this.showOverlay = true;
      axios
        .get(`${environment.BASE_URL}/aluno/pagamento/${pagamento.id}` +
          `/responsavel/${this.$store.state.usuario?.cpf}/status2`,
          {
            isAuthenticated: true,
          })
        .then((res) => {
          if (res.data.status !== "FAIL") {
            this.status = res.data.result;
            this.resposta.forEach((el) => {
              if (el["id"] == pagamento.id) {
                el["status"] = this.status;
              }
            });
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false;
        });
    },
  },
  computed: {
    aluno: {
      get() {
        return this.$store.state.aluno;
      },
      set(valor) {
        this.$store.commit("setAluno", valor);
      },
    },
    matricula: {
      get() {
        return this.$store.state.matricula;
      },
      set(valor) {
        this.$store.commit("setMatricula", valor);
      },
    },
  },
  created() {
    this.$utils.scrollUp();
    if (Object.keys(this.aluno).length === 0) {
      this.$router.push("/alunos");
    } else {
      axios
        .get(
          `${environment.BASE_URL}/aluno/${this.aluno.cpf}/matricula/${this.matricula.numero}/pagamentos`,
          {
            isAuthenticated: true,
          }
        )
        .then((res) => {
          if (res.data.status === "FAIL") {
            this.message = res.data.message;
            this.toast = true;
          } else {
            this.resposta = res.data.result;
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false;
        });
    }
  },
};
</script>
