<template>
  <div>
    <Toast v-if="toast" :message="message" />
    <Cards :nivel="2"></Cards>
    <div class="card">
      <h5 class="card-header">Matrículas</h5>
      <div class="card-body">
        <Overlay :exibir="showOverlay">
          <div style="display: grid !important">
            <div class="table-responsive text-center mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Número</th>
                    <th scope="col">Modalidade</th>
                    <th scope="col">Pagamentos</th>
                  </tr>
                </thead>
                <tbody v-if="Object.keys(resposta).length">
                  <tr v-for="item in resposta" :key="item.numero">
                    <td>{{ item.numero }}</td>
                    <td>{{ item.modalidade }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-link"
                        @click.prevent="verPagamentos(item)"
                      >
                        <i
                          class="bi-cash"
                          style="font-size: 1.5rem; color: cornflowerblue"
                        ></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <td colspan="5">Nenhum registro encontrado!</td>
                </tbody>
              </table>
            </div>
          </div>
        </Overlay>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-outline-secondary mt-3"
      @click.prevent="this.$router.go(-1)"
    >
      Voltar
    </button>
  </div>
</template>

<script>
import axios from "axios";
import environment from "@/environment";
import Overlay from "../template/bootstrap/Overlay.vue";
import Toast from "../template/bootstrap/Toast.vue";
import Cards from "../template/Cards.vue";

export default {
  name: "MatriculaItem",
  components: { Overlay, Toast, Cards },
  data: function () {
    return {
      resposta: {},
      showOverlay: true,
      toast: false,
      message: "",
    };
  },
  methods: {
    verPagamentos(matricula) {
      this.$store.commit("setMatricula", matricula);
      this.$router.push("/pagamentos");
    },
  },
  computed: {
    aluno: {
      get() {
        return this.$store.state.aluno;
      },
      set(valor) {
        this.$store.commit("setAluno", valor);
      },
    },
  },
  mounted() {
    this.$utils.scrollUp();
    if (Object.keys(this.aluno).length === 0) {
      this.$router.push("/alunos");
    } else {
      axios
        .get(`${environment.BASE_URL}/aluno/${this.aluno.cpf}/matriculas`, {
          isAuthenticated: true,
        })
        .then((res) => {
          if (res.data.status === "FAIL") {
            this.message = res.data.message;
            this.toast = true;
          } else {
            this.resposta = res.data.result;
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.message = error;
          this.toast = true;
          this.showOverlay = false;
        });
    }
  },
};
</script>
