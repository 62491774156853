<template>
  <div>
    <div class="mt-3 mb-5">
      <div class="row">
        <div class="col-sm-7">
          <h4>
            <span>
              Gerencie os pagamentos das suas matrículas na Vila Olímpica
            </span>
          </h4>
        </div>
        <div class="col-sm-5 text-center my-3">
          <router-link to="/alunos"
                       class="btn btn-success">
            <h5 class="mx-2 mb-2 mt-1">Clique aqui pra acessar</h5>
          </router-link>
        </div>
      </div>
      <div class="mt-3">
        <p>
          Gere Documento de Arrecadação de Receitas (DAR) para quitação das mensalidades referentes
          às modalidades praticadas na Vila Olímpica e acompanhe o status de pagamento.
        </p>
      </div>
    </div>

    <div class="info mt-5">
      <div>
        <a data-bs-toggle="collapse"
           href="#oquee">
          <h5>- O que é?</h5>
        </a>
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse"
                 id="oquee">
              <div class="card card-body">
                <p>É um sistema que permite o usuário gerar Documento de Arrecadação de Receitas (DAR) para quitação das
                  mensalidades referentes às modalidades praticadas na Vila Olímpica.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info">
        <div>
          <a data-bs-toggle="collapse"
             href="#oqueprecisa">
            <h5>- O que precisa?</h5>
          </a>
          <div class="row">
            <div class="col">
              <div class="collapse multi-collapse"
                   id="oqueprecisa">
                <div class="card card-body">
                  <p>1. Estar matriculado em uma modalidade na Vila Olímpica ou estar cadastrado como responsável de um
                    menor matriculado.</p>
                  <p>2. Ter conta no gov.br (<a href="https://www.gov.br/pt-br/servicos/criar-sua-conta-gov.br">Criar sua
                      conta gov.br</a>)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info">
        <div>
          <a data-bs-toggle="collapse"
             href="#como-proceder">
            <h5>- Como proceder?</h5>
          </a>
          <div class="row">
            <div class="col">
              <div class="collapse multi-collapse"
                   id="como-proceder">
                <div class="card card-body">
                  <p>1. Acessar pelo botão acima;</p>
                  <p>2. Fazer login com a conta gov.br</p>
                  <p>3. Escolher o aluno</p>
                  <p>4. Escolher a modalidade matriculada</p>
                  <p>5. Escolher o mês a ser gerado o DAR;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a data-bs-toggle="collapse"
           href="#automaticamente"
           aria-expanded="false"
           aria-controls="automaticamente">
          <h5>- O Documento de Arrecadação de Receitas (DAR) é gerado automaticamente?</h5>
        </a>
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse"
                 id="automaticamente">
              <div class="card card-body">
                <p><b>Não.</b> Após o usuário entrar no sistema, ele deve:</p>
                <p>1 - selecionar um aluno pelo qual é responsável;</p>
                <p>2 - selecionar a matrícula referente a modalidade desejada;</p>
                <p>3 - selecionar o período disponível;</p>
                <p>4 - gerar o Documento de Arrecadação de Receitas (DAR).</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a data-bs-toggle="collapse"
           href="#aberto"
           aria-expanded="false"
           aria-controls="aberto">
          <h5>- Paguei o Documento de Arrecadação de Receitas (DAR), mas no sistema ainda
            mostra o status 'Em aberto'. O que fazer?</h5>
        </a>
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse"
                 id="aberto">
              <div class="card card-body">
                <p>A compensação bancária pode ocorrer em até 72 horas. Caso já tenha pago, aguarde até a atualização do
                  status. Se o Documento de Arrecadação de Receitas (DAR) já está vencido e não foi pago, é necessário
                  gerar
                  um novo DAR para o período desejado.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a data-bs-toggle="collapse"
           href="#vencido"
           aria-expanded="false"
           aria-controls="vencido">
          <h5>- Qual é o vencimento do Documento de Arrecadação de Receitas (DAR) que eu gerei?</h5>
        </a>
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse"
                 id="vencido">
              <div class="card card-body">
                <p>O vencimento do Documento de Arrecadação de Receitas (DAR) é de 2 (dois) dias a partir do dia que ele
                  foi gerado.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeItem",
  data: function () {
    return {};
  },
};
</script>

<style>
.info a {
  text-decoration: none;
}

.info>div {
  margin-bottom: 3px;
}

.info .card {
  border: none !important;
}
</style>