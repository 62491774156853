import { createApp } from "vue";
import App from "./App.vue";

import "./config/bootstrap";
import store from "./config/store";
import router from "./config/router";
import "./config/axios";

import Keycloak from "keycloak-js";
import environment from "@/environment";

import global from "@/global";

import { VueClipboard } from "@soerenmartius/vue3-clipboard";

import { updateToken } from "@/middlewares/updateToken";

const options = {
  url: `${environment.SSO_URL}`,
  realm: `${environment.SSO_REALM}`,
  clientId: `${environment.SSO_CLIENT_ID}`,
};

const _keycloak = new Keycloak(options);

let app = null;

_keycloak.init({ onLoad: "check-sso", checkLoginIframe: false }).then(() => {
  app = createApp(App);
  app.use(router);
  app.use(store);
  app.use(VueClipboard);
  app.config.globalProperties.$keycloak = _keycloak;
  app.config.globalProperties.$tempo = global.tempo;
  app.config.globalProperties.$utils = global.utils;
  app.config.globalProperties.$filters = {
    currencyBRL(valor) {
      return (
        "R$ " +
        valor.toLocaleString("pt-BR", {
          currency: "BRL",
          minimumFractionDigits: 2,
        })
      );
    },
  };
  app.mount("#app");

  setInterval(async () => {
    // Se não tiver autenticado, não chamar o updateToken para não
    // redirecionar da home para a tela de login do SSO
    if (app.config.globalProperties.$keycloak.authenticated) {
      updateToken(
        window.location.origin + window.location.pathname,
        function () {}
      );
    }
  }, 50000);
});

export { app };
