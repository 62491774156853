<template>
  <div class="row">
    <div class="col mb-2">
      <a href="#" class="float-end"
         @click.prevent="sair()">Sair</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-auto mb-3"
         v-if="nivel >= 1">
      <div class="card"
           style="width: 18rem">
        <h5 class="card-header">Responsável</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-auto"><b>Nome:</b></div>
          </div>
          <div class="row">
            <div class="col-md-auto">{{ usuario.nome }}</div>
          </div>
          <div class="row">
            <div class="col-md-auto"><b>CPF:</b></div>
          </div>
          <div class="row">
            <div class="col-md-auto">{{ $utils.formatarCPF(usuario.cpf) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-auto mb-3"
         v-if="nivel >= 2">
      <div class="card"
           style="width: 18rem">
        <h5 class="card-header">Aluno</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-auto"><b>Nome:</b></div>
          </div>
          <div class="row">
            <div class="col-md-auto">{{ aluno.nome }}</div>
          </div>
          <div class="row">
            <div class="col-md-auto"><b>CPF:</b></div>
          </div>
          <div class="row">
            <div class="col-md-auto">{{ $utils.formatarCPF(aluno.cpf) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-auto mb-3"
         v-if="nivel >= 3">
      <div class="card"
           style="width: 18rem">
        <h5 class="card-header">Matrícula</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-auto"><b>Número:</b></div>
          </div>
          <div class="row">
            <div class="col-md-auto">{{ matricula.numero }}</div>
          </div>
          <div class="row">
            <div class="col-md-auto"><b>Modalidade:</b></div>
          </div>
          <div class="row">
            <div class="col-md-auto">{{ matricula.modalidade }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/middlewares/updateToken";

export default {
  name: "CardsItem",
  props: ["nivel"],
  methods: {
    sair() {
      logout();
    },
  },
  computed: {
    usuario: {
      get() {
        return this.$store.state.usuario;
      },
      set(valor) {
        this.$store.commit("setUsuario", valor);
      },
    },
    aluno: {
      get() {
        return this.$store.state.aluno;
      },
      set(valor) {
        this.$store.commit("setAluno", valor);
      },
    },
    matricula: {
      get() {
        return this.$store.state.matricula;
      },
      set(valor) {
        this.$store.commit("setMatricula", valor);
      },
    },
  },
};
</script>
