<template>
    <div class="content">
        <div class="container-fluid">
            <div class="container-sm">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyContent'
}
</script>

<style>
.content {
    grid-area: content;
    padding: 20px 0;
}

@media (min-width: 1140px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1140px !important;
  }
}
</style>